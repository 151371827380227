


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { router } from '../../helpers';
import { UserList } from '../../models/user-list.model';

@Component({})
export default class UserListComponent extends Vue {
  account: any = this.$store.state.account;
  users: any = this.$store.state.users;
  application: any = this.$store.state.application;
  userList: UserList = new UserList();

  created(): void {
    if (this.$cookies.isKey(this.userList.cookiesKey)) {
      this.users.search = this.$cookies.get(this.userList.cookiesKey);
    }

    this.loadUsers();
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  selectUser(event: any): void {
    router.push(`/user/${event.Id}`);
  }
  createNew(): void {
    router.push(`/create-user`);
  }
  onPage(page: number): void {
    this.users.search.actualPage = page;
    this.loadUsers();
  }
  onSort(field: any): void {
    this.users.search.orderBy = field[0];
    this.loadUsers();
  }
  onSortDesc(desc: any): void {
    this.users.search.orderByAsc = !desc[0];
    this.loadUsers();
  }
  onItemsPerPage(field: any): void {
    this.users.search.pageRows = field === -1 ? this.users.totalCount : field;
    this.loadUsers();
  }
  updateUsers(): void {
    this.users.search.actualPage = 1;
    this.loadUsers();
  }
  loadUsers(): void {
    const searchQuery = this.users.search;
    this.$cookies.set(this.userList.cookiesKey, JSON.stringify(searchQuery), Infinity);

    (this as any).$store.dispatch('users/getAll', searchQuery);
  }
  get breadcrumbs() {
    return [
      {
        text: 'Uživatelé',
        disabled: true
      }
    ];
  }
}
