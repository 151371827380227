




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class WorkRecordTable extends Vue {
  workRecords: any = this.$store.state.workRecords;
  application: any = this.$store.state.application;

  @Prop()
  workRecord: any;
  @Prop()
  projects: any;
  @Prop()
  dialog: any;
  @Prop()
  project: any;
  @Prop()
  ticket: any;

  created() {
    if (this.project) {
      this.workRecord.detail.projectId = this.project;
    }

    if (this.ticket) {
      this.workRecord.detail.ticketId = this.ticket;
    }
  }

  save() {
    this.$emit('save', this.workRecord);
  }

  cancel() {
    this.$emit('cancel');
  }

  customFilter(item: any, queryText: string, itemText: string) {
    const contactName = item.ContactName?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const name = item.Name?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const searchText = queryText
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return contactName.indexOf(searchText) > -1 || name?.indexOf(searchText) > -1;
  }

  get projectIdEdit() {
    return this.workRecord.detail.projectId;
  }

  @Watch('projectIdEdit')
  onProjectId(value: number) {
    if (value) {
      (this as any).$store.dispatch('workRecords/getWorkTypes', { projectId: value });
    } else {
      //this.workRecords.workTypes.items = [];
      //this.workRecords.workTypes.totalCount = 0;
    }
  }
}
