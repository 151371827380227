













































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { router } from '../../helpers';
import { TicketList } from '../../models/ticket-list.model';
import debounce from 'lodash/debounce';

@Component({})
export default class TicketListComponent extends Vue {
  account: any = this.$store.state.account;
  tickets: any = this.$store.state.tickets;
  application: any = this.$store.state.application;
  ticketList: TicketList = new TicketList();
  fab = null;
  autocompleteSearch = '';

  created(): void {
    if (this.$cookies.isKey(this.ticketList.cookiesKey)) {
      this.tickets.search = this.$cookies.get(this.ticketList.cookiesKey);
    }

    this.loadTickets();
    this.search = debounce(this.search, 1500);
  }

  updated() {
    this.$recaptchaInstance?.hideBadge();
  }

  selectTicket(event: any): void {
    router.push(`/ticket/${event.Id}`);
  }
  createNew(): void {
    router.push(`/create`);
  }
  onPage(page: number): void {
    this.tickets.search.page = page;
    this.loadTickets();
  }
  setFilter(filterId: string, option: any) {
    option.Selected = !option.Selected;

    this.tickets.search.filterParameters = {};

    this.tickets.filters.forEach((filter: any) => {
      this.tickets.search.filterParameters[filter.Id] = [];

      filter.Options.forEach((filterOption: any) => {
        if (filterOption.Id != option.Id && filterOption.Selected) {
          this.tickets.search.filterParameters[filter.Id].push(filterOption.Value);
        }
      });

      if (filterId === filter.Id && !option.Selected) {
        this.tickets.search.filterParameters[filter.Id].push(option.Value);
      }
    });

    this.tickets.search.page = 1;

    this.loadTickets();
  }

  setFilterNew() {
    this.tickets.search.filterParameters = {};

    this.tickets.filters.forEach((filter: any) => {
      this.tickets.search.filterParameters[filter.Id] = [];
      this.tickets.search.filterParameters[filter.Id] = filter.Values;
    });

    this.tickets.search.page = 1;
    this.autocompleteSearch = '';

    this.loadTickets();
  }

  clearSearchText() {
    this.tickets.search.searchQuery = '';
    this.search();
  }

  get searchText() {
    return this.tickets.search.searchQuery;
  }

  @Watch('searchText')
  onVariable() {
    this.search();
  }

  search(): void {
    this.tickets.search.page = 1;

    this.loadTickets();
  }

  quickFilter() {
    this.tickets.search.page = 1;
    this.loadTickets();
  }

  loadTickets(): void {
    const search = this.tickets.search;
    this.$cookies.set(this.ticketList.cookiesKey, JSON.stringify(search), Infinity);

    (this as any).$store.dispatch('tickets/search', search);
  }

  getCount(filterId: number) {
    return this.tickets.filters.find((filter: any) => filter.Id === filterId).Options.filter((option: any) => option.Selected).length;
  }

  customFilter(item: any, queryText: string, itemText: string) {
    const label = item.Label?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const parentName = item.ParentName?.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const searchText = queryText
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return label.indexOf(searchText) > -1 || parentName?.indexOf(searchText) > -1;
  }

  remove(values: any, id: any) {
    const index = values.indexOf(id);
    if (index >= 0) {
      values.splice(index, 1);
      this.setFilterNew();
    }
  }

  get breadcrumbs() {
    return [
      {
        text: 'Tikety',
        disabled: true
      }
    ];
  }
}
